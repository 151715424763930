.form input[type="text"],
.form input[type="password"],
.form input[type="email"] {
  background: none;
  outline: none;
  border: none;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  color: #f3f3f3;
  font-size: 15px;
  z-index: 1;
}

.form input[type="file"] {
  background: none;
  outline: none;
  border: none;
   width: 400px;
   min-width: 300px; 
  /* border-left: 1px solid white; */
  border-bottom: 1px solid white;
  
  
  color: #f3f3f3;
  font-size: 15px;
    line-height: 1.5;
    padding: .375rem .75rem;
}
.form input[type="file"]::-webkit-file-upload-button {
   padding: 0.5rem;
   background-color: transparent;
   border-color: white;
   border-radius: 5px;
   color: white;
   margin-right: 1.5rem;
  }
.img-holder {
  min-width: 300px;
  width: 400px;
  min-height: 300px;
  height: 400px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.img-holder img{
    width: 100%;
}
form .password-update-holder, .password-update-holder{
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    transition: ease-in;
    transform: none;
}
.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }