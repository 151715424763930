.collab-wrapper{
    box-shadow: 0px 0px 8px 1px var(--color-white);

}
.colab-card{
    min-width: 150px;
    min-height: 150px;
    justify-content: center;
    align-items: center;


}
.colab-img {
    /* filter: grayscale(); */
    
}

.colab-figure {
    min-width: 120px;
    min-height: 120px;

    background-size: contain;
}



