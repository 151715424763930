/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 16px;
  --color-primary: rgba(122, 9, 68, 1);
  --color-secondary: #ed740a;
  --color-tertiary: #f7ceab;
  --color-white: #fcfcfc;
  overflow-x: hidden;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
li,
a,
figcaption {
  color: white;
}

body {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    37deg,
    rgba(0, 0, 0, 1) 60%,
    rgba(122, 9, 68, 1) 100%
  );
  background: -webkit-linear-gradient(
    37deg,
    rgba(0, 0, 0, 1) 60%,
    rgba(122, 9, 68, 1) 100%
  );
  background: linear-gradient(
    37deg,
    rgba(0, 0, 0, 1) 60%,
    rgba(122, 9, 68, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#7a0944",GradientType=1);
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* -----------utils--------------- */
.small {
  text-align: end;
  font-size: 12px;
  color: #aca9a9;
}

.transparent {
  background-color: transparent;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: white !important;
}

.btn-secondary {
  background-color: black !important;
  border-color: var(--color-primary) !important;
}

.border-primary {
  border-color: var(--color-primary) !important;
}

.color-primary {
  color: var(--color-primary);
}

.place-content-center {
  place-content: center;
}
.shadow {
  box-shadow: 0px 0px 8px 1px var(--color-white);
}

.bg-alpha {
  background-color: rgba(0, 0, 0, 0.527);
}
.pointer {
  cursor: pointer;
}
/* -----------end utils--------------- */

footer,
.footer-text {
  width: 100%;
}

.footer-text {
  position: relative;
  margin-top: 3.5rem;
}

.circle-up {
  font-size: 5rem;
}

.btn-primary:hover,
.btn-secondary:hover,
.circle-up:hover {
  box-shadow: 0px 0px 8px 1px var(--color-white) !important;

  /* background: rgb(0,0,0);
  background: -moz-linear-gradient(225deg, rgba(0,0,0,1) 18%, rgba(122,9,68,1) 75%);
  background: -webkit-linear-gradient(225deg, rgba(0,0,0,1) 18%, rgba(122,9,68,1) 75%);
  background: linear-gradient(225deg, rgba(0,0,0,1) 18%, rgba(122,9,68,1) 75%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#7a0944",GradientType=1);
  background-repeat: no-repeat; */
}
.footer-ul-link {
  width: 180px;
}
.navbar-toggler:focus {
  box-shadow: 0px 0px 8px 1px var(--color-white);
}
.star-filled {
  color: #ffd43b;
}
.star-empty {
  color: transparent;
}
.latest-subtitle {
  font-size: 2rem;
}

.articles-hard-holder {
  gap: 0.3rem;
}
.articles-card {
  background-size: cover;
  min-height: 400px;
  align-content: center;
}
.articles-title,
.latest-title {
  color: white;
  padding: 1rem 0;
  font-size: xx-large;
}

.latest-title {
  font-size: x-large;
  background-color: rgba(0, 0, 0, 0.8);
}

.h-300 {
  height: 300px;
}

code {
  color: white !important;
}

.clamp {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.github-link {
  position: absolute;
  bottom: 60px;
  left: 40%;
  right: 40%;
}

input{
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;  
}

:-ms-input-placeholder {  
  text-align: center; 
}

textarea{
  background-color: transparent !important ;
  color: white !important;
}