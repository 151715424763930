.hola {
    font-size: 6rem;
    margin-left: 2rem;
  }
  
  .hero-name {
    font-size: 3rem;
  }
  
  .border-shadow {
    box-shadow: 0px 0px 8px 1px var(--color-white);
  }
  

.hero-wrapper {
    margin-top: 10rem;
    position: relative;
    background-color: transparent;
    min-height: 50vh;
    line-height:60px;
    font-size: 32px;
    font-family: dosis_light;
    border:none;
    padding:0 15px;
    
}

.hero-wrapper::before,
.hero-wrapper::after{
    position: absolute;
    content: '';
    width: 20%;
    height: 20%;
}

.hero-wrapper::before{
    left:0;
    top:0;
    border-left:10px solid white;
    border-top:10px solid white;
}
.hero-wrapper::after{
    right:0;
    bottom:0;
    border-right:10px solid white;
    border-bottom:10px solid white;
}