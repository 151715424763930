.bmac-btn {
  background-image: url("https://res.cloudinary.com/dchrsbres/image/upload/v1725546264/seiglie/external/bmc-button_uqejhn.png");
  background-size: cover;
  height: 2rem;
  width: 8rem;
  cursor: pointer;
  display: inline-flex;
  border: 30%;
  border-radius: 10%;
  box-shadow: 0px 0px 1px rgba(190, 190, 190, 0.5);
  transition: 0.3s all linear;
  font-family: cursive;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    box-shadow: 0px 0px 2px 2px rgba(190, 190, 190, 0.5);
    opacity: 0.85;
    color: #ffffff;
  }
}
