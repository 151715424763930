/* General Style */
input {
   outline: none !important;
}

h2 {
   text-align: center;
   text-transform: uppercase;
   margin-bottom: 40px;
   font-weight: 700;
}

section#formHolder {
   padding: 50px 0;
   min-height: 80vh;
   align-content: center;
}

/* Brand Area */
.brand {
   padding: 20px;
   background-size: cover;
   background-position: center center;
   min-height: 280px;
   position: relative;
   box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
   transition: all 0.6s cubic-bezier(1.000, -0.375, 0.285, 0.995);
   z-index: 9999;
}

.brand.active {
   width: 100%;
}

.brand::before {
   content: '';
   display: block;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.85);
   z-index: -1;
}

.brand a.logo {
   color: var(--primaryColor);
   font-size: 20px;
   font-weight: 700;
   text-decoration: none;
   line-height: 1em;
}

.brand a.logo span {
   font-size: 30px;
   transform: translateX(-5px);
   display: inline-block;
}

.brand .heading {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   transition: all 0.6s;
}

.brand .heading.active {
   top: 100px;
   left: 100px;
   transform: translate(0);
}

.brand .heading figcaption {
   font-size: 70px;
   font-weight: 700;
   text-transform: uppercase;
   margin-bottom: 0;
}

.brand .heading p {
   font-size: 15px;
   font-weight: 300;
   text-transform: uppercase;
   letter-spacing: 2px;
   white-space: 4px;
   font-family: var(--mainFont);
}

.brand .success-msg {
   width: 100%;
   text-align: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   margin-top: 60px;
}

.brand .success-msg p {
   font-size: 25px;
   font-weight: 400;
   font-family: var(--mainFont);
}

.brand .success-msg a {
   font-size: 12px;
   text-transform: uppercase;
   padding: 8px 30px;
   background: var(--primaryColor);
   text-decoration: none;
   color: #fff;
   border-radius: 30px;
}

.brand .success-msg p,
.brand .success-msg a {
   transition: all 0.9s;
   transform: translateY(20px);
   opacity: 0;
}

.brand .success-msg p.active,
.brand .success-msg a.active {
   transform: translateY(0);
   opacity: 1;
}

/* Form Area */
.form {
   position: relative;
}
.bg-primary{
   background-color: var(--primaryColor)!important;
}
.form .form-piece {
   background: #fff;
   min-height: 480px;
   margin-top: 30px;
   box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
   color: var(--inputColor);
   padding: 0 0 60px 0;
   transition: all 0.9s cubic-bezier(1.000, -0.375, 0.285, 0.995);
   position: absolute;
   top: 0;
   left: -20%;
   width: 130%;
   overflow: hidden;
}

.form .form-piece.switched {
   transform: translateX(-100%);
   width: 100%;
   left: 0;
}

.form form {
   padding: 0 40px;
   margin: 0;
   width: 70%;
   position: absolute;
   top: 30%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.signup-form, login-form {
   width: 75%;
}
.form .form-group {
   margin-bottom: 5px;
   position: relative;
}

.form .form-group.hasError input {
   border-color: var(--primaryColor) !important;
}

.form .form-group.hasError label {
   color: var(--primaryColor) !important;
}

.form label {
   font-size: 12px;
   font-weight: 400;
   text-transform: uppercase;
   transform: translateY(40px);
   transition: all 0.4s;
   cursor: text;
   z-index: -1;
}

.form label.active {
   transform: translateY(10px);
   font-size: 10px;
}

.form label.fontSwitch {
   font-family: var(--mainFont) !important;
   font-weight: 600;
}

.form input[type="text"], .form input[type="password"], .form input[type="email"] {
   background: none;
   outline: none;
   border: none;
   display: block;
   padding: 10px 0;
   width: 100%;
   border-bottom: 1px solid #eee;
   color: #f3f3f3;
   font-size: 15px;
   z-index: 1;
}


.form input:not([type=submit]).hasError {
   border-color: var(--primaryColor);
}

.form span.error {
   color: var(--primaryColor);
   font-size: 12px;
   position: absolute;
   bottom: -20px;
   right: 0;
   display: none;
}

.form input[type=password] {
   color: white;
}

.form .CTA {
   margin-top: 30px;
}

.form .CTA input {
   font-size: 12px;
   text-transform: uppercase;
   padding: 5px 30px;
   background: var(--primaryColor);
   color: #fff;

   margin-right: 20px;
   border: none;
}

.form .CTA a.switch {
   font-size: 13px;
   font-weight: 400;
   color: var(--inputColor);
   text-decoration: underline;
   transition: all 0.3s;
}

.form .CTA a.switch:hover {
   color: var(--primaryColor);
}


.isLogin {
   transform: translateX(0%);
   width: 100%;
   left: 0
}

.isRegister {
   transform: translateX(100%);
   width: 100%;
   right: 0;
}
.login_register-wrapper{
   display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}


/* Responsive Styles */
@media (max-width: 770) {
   .container {
       overflow: hidden;
       min-height: 230px;
   }

   section#formHolder {
       padding: 0;
   }

   section#formHolder .brand {
       min-height: 270px !important;
   }

   section#formHolder .brand.active {
       min-height: 100vh !important;
   }

   section#formHolder .brand .heading.active {
       top: 350px;
       left: 50%;
       transform: translate(-50%, -50%);
   }

   section#formHolder .brand .success-msg p {
       font-size: 16px;
   }

   section#formHolder .brand .success-msg a {
       padding: 5px 30px;
       font-size: 10px;
   }

   .form {
       width: 80vw;
       min-height: 500px;
       margin-left: 10vw;
   }

   .form .form-piece {
       margin: 0;
       top: 0;
       left: 0;
       width: 100% !important;
       transition: all 0.5s ease-in-out;
   }

   .form .form-piece.isLogin {
       transform: translateY(-50%);
       width: 100%;
       left: 0;
   }
   .form .form-piece.isRegister {
      transform: translateY(0%);
      width: 100%;
      left: 0;
  }

   .form .form-piece > form {
       width: 100% !important;
       padding: 0 60px;
       left: 50%;
   }
}

@media (max-width: 640px) {
   .brand{
      width: 100% !important;
   }
   
   section#formHolder .form {
       width: 100vw !important;
       margin-left: 0;
   }


   section#formHolder {
      padding: 50px 0;
      min-height: 90vh;
     align-content: flex-start;
   }

   h3 {
       font-size: 50px !important;
   }
   .form .form-piece {
      transform: translateX(0%);
      width: 100% !important;
      left: 0
   }
   
.isLogin {
   transform: translateX(0%);
   width: 100% !important;
   left: 0;
   
}

.isRegister {
   transform: translateX(0%);
   width: 100% !important;
   right: 0;
   
}
   .form .form-piece.switched {
      transform: translateX(0);
      width: 100% !important;
      left: 0;
      
   }
   .login_register-wrapper{
      flex-direction: column;
   }
}
